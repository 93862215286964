export default {
  name: 'NewItinerary',

  methods: {
    viewImage(img) {
      this.img = this[img];
      this.showModal = true;
    }
  },

  data() {
    return {
      img1: require("@/assets/imgs/it/5.jpg"),
      img2: require("@/assets/imgs/it/6.jpg"),
      img3: require("@/assets/imgs/it/7.jpg"),
      img4: require("@/assets/imgs/it/8.jpg"),

      img: '',

      lazy: require("@/assets/imgs/dark-lazy.png"),
      showModal: false
    }
  }
}
